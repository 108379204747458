@import "variables"
@import "~bootstrap"

.sso-registration
    @extend .mt-4
    @extend .p-4
    background-color: #fafafa
    border: 1px solid #e0e0e0
    font-family: "Open Sans", sans-serif !important

    h1, h2, h3, h4, h5, a, button
        font-family: "Titillium Web", serif !important

    &.dark
        background-color: #111423
        border: none

        &, a
            color: $white

        a, button
            &.btn.btn-white
                border-color: $white

                &:hover
                    border-color: darken($white, 10%)

    &.block, &.modal-body
        @extend .mt-sm-0

    &.inline
        @extend .my-5

        .description
            min-height: 48px
            line-height: 40px
            font-size: 24px

            @include media-breakpoint-down(sm)
                font-size: 20px

    &.modal-body, &.modal-header
        border-radius: 0

    p
        font-weight: 400
        font-style: normal
        font-size: 18px
        line-height: 28px
        color: #4f4f4f

    input[type="date"], input[type="datetime-local"], input[type="datetime"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="range"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], select, textarea
        font-family: "Open Sans", sans-serif !important
        border: 1px solid #4f4f4f
        box-sizing: border-box
        border-radius: 2px
        padding: 11px 13px
        color: #4f4f4f
        caret-color: $primary
        font-weight: 400
        font-size: 16px
        line-height: 24px

        &:focus
            border-color: #bdbdbd
            box-shadow: none

        &.error, &.is-invalid
            border-color: $danger

        &:disabled
            border-color: #bdbdbd
            color: #828282
            background-color: #fff

    .invalid-feedback
        @extend .d-block
        color: $danger !important
        font-size: 14px !important
        line-height: 17px !important
        font-weight: 400 !important

    select.form-control
        border: 1px solid #4f4f4f
        border-radius: 2px
        padding: 11px 13px
        height: 48px

    a,
    button
        &.btn
            font-weight: 900
            font-size: 16px
            line-height: 32px
            text-align: center
            letter-spacing: 0.02em
            text-transform: uppercase
            text-decoration: none
            border-radius: 0
            padding: 7px 36px

            &.btn-primary
                background-color: $primary
                color: #fff
                border: 1px solid $primary

                &:hover
                    background-color: #e30907

            &.btn-white
                background-color: $white
                color: $dark
                border: 1px solid $dark

                &:hover
                    background-color: darken($white, 10%)


    .wb-wrap, .not-you-wrap
        @extend .row

        .not-you, h5
            font-size: 18px !important
            font-weight: 400 !important
            font-style: normal !important
            line-height: 24px !important
            text-transform: initial !important

    .not-you
        @extend .border-0
        @extend .p-0
        background: transparent !important
        color: $primary !important
        text-decoration: underline !important

    .btn-register, .btn-download
        @extend .btn
        @extend .btn-primary
        @extend .w-100

    .tabs
        .tab
            @extend .p-0
            @extend .d-block
            text-transform: uppercase !important
            text-align: center !important
            font-size: 20px
            line-height: 56px
            font-weight: 700
            border: none
            background: transparent !important
            color: $black
            text-decoration: none
            border-bottom: 3px solid transparent

            &:hover
                border-bottom: 3px solid $black

            &.active
                color: $primary
                border-bottom: 3px solid $primary !important

                &:hover
                    color: darken($primary, 10%)
                    border-color: darken($primary, 10%) !important

            &.tab-login
                @extend .col-5

            &.tab-registration
                @extend .col-7

#continue-reading-block
    background-color: #f4f4f4
    padding: 1.5em

    @media (max-width: 415px)
        padding: 1em

    h1, h2, h3, h4, h5, a, button
        font-family: "Titillium Web", serif !important
        color: #000

    p
        font-family: "Open Sans", sans-serif !important
        color: #000

    h1, h2, h3, h4, h5
        font-weight: 700

    .action-wrapper
        padding-bottom: 1.5em
        border-bottom: 1px solid rgba(37, 46, 87, 0.1)

        h3.title
            font-size: 32px

        p.highlighted
            font-weight: 700
            margin-bottom: 0

        .action-buttons
            margin-top: 1em

        .button
            margin-right: 1em
            border-radius: 0

        .button-white
            background-color: #fff
            border-color: #000

        .button-red
            background-color: #fd0006
            border-color: #fd0006
            color: #fff

            @media (max-width: 685px)
                margin: 1em 0
                display: block
                width: 300px

            @media (min-width: 1024px) and (max-width: 1599px)
                margin: 1em 0
                display: block
                width: 300px

            @media (max-width: 415px)
                width: 256px
                font-size: 13px

            &:last-of-type
                margin-bottom: 0

    .benefits-wrapper
        padding-top: 1.5em

        & > h4
            font-size: 24px

        & > ul
            padding: 0
            margin: 1.5em 0 0 0

            @media (min-width: 40em)
                columns: 2

            li
                background: url("../assets/list-red-circle.png") no-repeat 0 10px transparent
                list-style-type: none
                padding: 0 0 1px 12px
                vertical-align: middle
                break-inside: avoid

                margin: 0 0 1em 0

                p
                    margin: 0
                    font-size: 18px

                h5
                    font-size: 20px

#subscribe-link
    #sso-header-register, & > a
        @extend .p-0
        @extend .text-uppercase
        @extend .d-inline
        font-weight: 700
        color: #000
        font-size: 15px
        cursor: pointer

.spinner-border.text-primary
    // Unfortunately, on some pages bootstrap is already preloaded via CDN so we need to additionally override styles.
    color: $primary !important

.progress
    border-radius: 0 !important

    .progress-bar
        // Unfortunately, on some pages bootstrap is already preloaded via CDN so we need to additionally override styles.
        background-color: $primary !important
